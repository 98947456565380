<template>

</template>

<script>
export default {
    name: "WxLogin",
    data() {
        return {
            code: null,
            loading: null,
            game_id: null
        }
    },
    created() {
        this.code = this.$route.query.code
        this.game_id = this.$route.query.gameId
        this.loading = this.$loading({
            lock: true,
            text: '正在登录中...请稍后',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        // 取得code后 请求后台 获取用户信息再返回
        this.getUserInfo(this.code)
    },
    methods: {
        getUserInfo(code) {
            var that = this
            this.boxRequest({
                url: "/Home/User/checkLoginByWechat",
                method: "post",
                data: { code: code }
            }).then(result => {
                if(result.data.code == 200) {
                    that.$message({
                        type: "success",
                        message: "登录成功！"
                    })
                    that.loading.close()
                    // 保存token
                    that.Public.setCookie('token', result.data.tokens)
                    that.$store.commit('updateToken', result.data.tokens)
                    that.$store.commit('updateUserInfo', result.data.result)
                    that.$store.commit('updateIsLogin', true)
                    if(that.game_id == 'undefined' || that.game_id == null) {
                        that.$router.push('/')
                    } else {
                        that.$router.push('/play?gameId='+that.game_id)
                    }
                }
            }).catch(error => {
                that.$message("服务器出现异常！登录失败！");
            })
        }
    }
}
</script>

<style scoped>

</style>